<template>
  <el-table
    ref="tableControl"
    :key="updateTable"
    v-loading="isLoading"
    class="table-profile"
    :data="tableData"
    :row-class-name="tableRowClassName"
    stripe
    @selection-change="handleSelectionChange"
    @cell-mouse-enter="handleCellMouseEnter"
    @cell-mouse-leave="handleCellMouseLeave"
  >
    <template v-for="item in tableFields">
      <el-table-column
        :key="item.id"
        :width="item.width ? item.width : ''"
        :class-name="`table-profile__${item.prop}`"
        :sortable="item.sortKey"
      >
        <template slot="header">
          <TableLabel
            :data="item"
            :sortable="item.sortKey"
            @sortBy="handleSort"
          />
        </template>

        <template slot-scope="scope">
          <slot :data="{ row: scope.row, prop: item.prop }" />
        </template>
      </el-table-column>
    </template>

    <el-table-column
      align="right"
      width="160px"
      class-name="table-control__icon"
    >
      <template slot-scope="scope">
        <el-button
          type="warning"
          class="table-profile__btn"
          @click.native="handleEdit(scope.row)"
        >
          Редактировать
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { controlTableRemoveSelectedItems } from '@/views/control/data/control'
import TableLabel from '@/UI/table/table-body/components/TableLabel'
export default {
  name: 'ProfileTableBody',
  components: { TableLabel },
  props: {
    tableData: { type: Array, required: true },
    tableFields: { type: Array, required: true },
    hasIconEdit: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      multipleSelectionList: [],
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      multipleSelectionLocalNames: [],
      hoverRowIds: [],
    }
  },
  computed: {
    updateTable() {
      return 'desktop' + this.tableFields.length
    },
  },
  methods: {
    isDropdownItemDisabled({ value }) {
      return (
        value === controlTableRemoveSelectedItems &&
        this.multipleSelectionIds.length === 0
      )
    },
    tableRowClassName({ row }) {
      if (this.multipleSelectionIds.includes(row.id)) {
        return 'active-row'
      }
      if (this.hoverRowIds.includes(row.id)) {
        return 'hover-row'
      }

      return ''
    },
    handleSelectionChange(val) {
      this.multipleSelectionList = val
      this.multipleSelectionIds = val.map(item => item.id)
      this.multipleSelectionNames = val.map(item => item.name)
      this.multipleSelectionLocalNames =
        val.map(item => (item.local_name ? item.local_name : item.name)) || []
      this.$emit('selectionChange', {
        ids: this.multipleSelectionIds,
        names: this.multipleSelectionNames,
        local_names: this.multipleSelectionLocalNames,
      })
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
    handleEdit(row) {
      this.$emit('editRow', row)
    },
    handleDelete(row) {
      this.$emit('deleteRow', row)
    },
    handleSort(sortKey) {
      this.$emit('tableSortByKey', sortKey)
    },
    handleCellMouseEnter(row) {
      this.hoverRowIds = []
      this.hoverRowIds.push(row.id)
    },
    handleCellMouseLeave() {
      this.hoverRowIds = []
    },
  },
}
</script>
